<template>
	<!-- 步骤三 -->
	<div class="stepThree-page-all">
		<div class="stepThree-pageTitle" v-if="which==2">个人讲师入驻</div>
		<div class="stepThree-pageTitle" v-if="which==1">机构入驻</div>
		<div class="stepThree-pageLine"></div>
		<!-- 标签 -->
		<stepitem type="3"></stepitem>
		<!-- 正文 -->
		<div class="stepThree-pageContent">
			<img src="../../../assets/img/organize/time.png" class="stepThree-timeIcon">
			<div class="stepThree-contentTetx1">提交成功!</div>
			<div class="stepThree-contentTetx2">等待审核，时间一般1-3个工作日</div>
			<div class="stepThree-pageButton" @click="toNext()">去首页</div>
		</div>
	</div>
</template>

<script>
	import stepitem  from '../../common/stepitem.vue';
	export default {
		data() {
			return {
				list:[{},{},{},{}],
				which:1
			};
		},
		components: {
			stepitem
		},
		created() {
			this.which = this.$route.query.e
		},
		methods: {
			// 下一步按钮
			toNext(){
				this.$router.push({
					path: '/enter',
				})
			}
		}
	};
</script>

<style >
	.stepThree-page-all {
		width: 55%;
		height: 920px;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
	}
	.stepThree-pageTitle{
		width: 100%;
		height: 130px;
		font-size: 48px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #151515;
		line-height: 130px;
		text-align: center;
	}
	.stepThree-pageLine{
		width: 100%;
		height: 1px;
		background: #E6E6E6;
	}
	.stepThree-pageContent{
		width: 850px;
		height: 530px;
		margin: 0 auto;
		text-align: center;
	}
	
	.stepThree-timeIcon{
		margin-top: 100px;
		margin-bottom: 25px;
		width: 101px;
		height: 101px;
	}
	
	.stepThree-contentTetx1{
		font-size: 20px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		margin-bottom: 25px;
	}
	
	.stepThree-contentTetx2{
		font-size: 16px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
		margin-bottom: 50px;
	}
	
	.stepThree-pageButton{
		width: 146px;
		height: 54px;
		background: #00D0B0;
		font-size: 20px;
		 font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 0 auto;
		
	}
	.stepThree-biaoqian2 {
		position: absolute;
		z-index: 2;
		width: 343px;
		height: auto;
	}
	.stepThree-biaoqian1 {
		position: absolute;
		z-index: 1;
		width: 343px;
		height: auto;
	}
</style>
